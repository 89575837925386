import { DatePipe } from "@angular/common";
import { Component, input } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { getDateTimeFormatSignal } from "../../../../services/date.service";
import { PublicationAggregatedTilesViewItemWithSummary } from "../../../domain/models/publication-tiles-view";

@Component({
  standalone: true,
  selector: "app-publication-tile-footer",
  templateUrl: "./publication-tile-footer.component.html",
  styleUrl: "./publication-tile-footer.component.scss",
  imports: [TranslateModule, MatIconModule, DatePipe],
})
export class PublicationTileFooterComponent {
  protected readonly dateTimeFormat = getDateTimeFormatSignal();
  public readonly publication =
    input.required<PublicationAggregatedTilesViewItemWithSummary>();
}
