import { NgModule } from "@angular/core";
import { angularProvidersBuilder } from "../../../../harmony/angular";
import { PublicationService } from "../data/publication.service";
import { GetPublicationAggregatedListWithIntentsInteractor } from "./interactors/get-publication-aggregated-list-with-intents.interactor";
import { GetPublicationAggregatedListWithSummaryInteractor } from "./interactors/get-publication-aggregated-list-with-summary.interactor";
import { GetPublicationAggregatedTilesWithSummaryInteractor } from "./interactors/get-publication-aggregated-tiles-with-summary.interactor";
import {
  PublicationDefaultProvider,
  PublicationProvider,
} from "./publication.provider";

@NgModule({
  providers: angularProvidersBuilder({
    provide: PublicationProvider,
    deps: [PublicationService],
    useFactory: (publicationService: PublicationService) =>
      new PublicationDefaultProvider(publicationService),
  })
    .add(GetPublicationAggregatedListWithSummaryInteractor, (p) =>
      p.provideGetPublicationAggregatedListWithSummary(),
    )
    .add(GetPublicationAggregatedListWithIntentsInteractor, (p) =>
      p.provideGetPublicationAggregatedListWithIntents(),
    )
    .add(GetPublicationAggregatedTilesWithSummaryInteractor, (p) =>
      p.provideGetPublicationAggregatedTilesWithSummary(),
    )
    .build(),
})
export class PublicationProviderModule {}
