<button mat-icon-button (click)="prevPage()" [disabled]="currentPage() === 1">
  <mat-icon>chevron_left</mat-icon>
</button>
<div class="text">
  <span>Page</span>
  <input
    type="text"
    [value]="currentPage()"
    (input)="goToPage(+$event.target.value)"
  />
  <span>of</span>
  <span>{{ totalPages() }}</span>
</div>
<button
  mat-icon-button
  (click)="nextPage()"
  [disabled]="currentPage() === totalPages()"
>
  <mat-icon>chevron_right</mat-icon>
</button>
