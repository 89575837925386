import { ChartData } from "./chart-data.model";

export class ChartDataGroup {
  constructor(
    public name: string,
    public readonly color: string,
    public readonly dataList: ChartData[],
    public readonly visible = true,
  ) {}
}
