import { Component, EventEmitter, input, Output } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { BrandPublicationTemplateListItemActionsComponent } from "../../../../brand/brand-campaign/content/components/brand-publication-template-list-item-actions/brand-publication-template-list-item-actions.component";
import { SharedPipesModule } from "../../../pipes/shared-pipes.module";
import { PublicationAggregatedTilesViewItemWithSummary } from "../../domain/models/publication-tiles-view";
import { PublicationTileFooterComponent } from "./publication-tile-footer/publication-tile-footer.component";

@Component({
  standalone: true,
  selector: "app-publication-tile",
  templateUrl: "./publication-tile.component.html",
  styleUrl: "./publication-tile.component.scss",
  imports: [
    TranslateModule,
    SharedPipesModule,
    PublicationTileFooterComponent,
    BrandPublicationTemplateListItemActionsComponent,
  ],
})
export class PublicationTileComponent {
  @Output() public readonly remove = new EventEmitter<void>();
  public readonly areActionVisible = input.required<boolean>();
  public readonly publication =
    input.required<PublicationAggregatedTilesViewItemWithSummary>();
}
