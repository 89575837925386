import { Chart, ChartOptions, registerables, ChartData } from "chart.js";

import { registerCustomPositioner } from "../custom-positioner";
import { ChartDataGroup } from "../models/chart-data-group.model";

export class ChartJSLineChart {
  constructor() {
    Chart.register(...registerables);
    registerCustomPositioner();
  }

  public createOrUpdate(
    canvas: HTMLCanvasElement,
    dataGroupList?: ChartDataGroup[],
    options?: ChartOptions,
    withAnimation = false,
  ) {
    /* eslint-disable-next-line no-prototype-builtins */
    if (canvas.hasOwnProperty("$chartjs")) {
      return this.update(canvas, dataGroupList, withAnimation);
    }
    return this.build(canvas, dataGroupList, options);
  }

  private build(
    canvas: HTMLCanvasElement,
    dataGroupList?: ChartDataGroup[],
    options?: ChartOptions,
  ): Chart {
    return new Chart(canvas, {
      type: "line",
      data: this.getDatasets(dataGroupList),
      options: options,
    });
  }

  private update(
    canvas: HTMLCanvasElement,
    dataGroupList?: ChartDataGroup[],
    withAnimation = false,
  ): Chart {
    const chart = Chart.getChart(canvas) as Chart;

    if (chart) {
      chart.data = this.getDatasets(dataGroupList);
      chart.update(withAnimation ? undefined : "none");
    }

    return chart;
  }

  private getDatasets(dataGroupList?: ChartDataGroup[]): ChartData<"line"> {
    dataGroupList = dataGroupList ?? [];

    return {
      labels: dataGroupList[0]?.dataList?.map((dataGroup) => dataGroup.x) ?? [],
      datasets: dataGroupList.map((dataGroup) => ({
        clip: false,
        label: dataGroup.name,
        borderColor: dataGroup.color,
        borderCapStyle: "round",
        borderJoinStyle: "round",
        pointBackgroundColor: dataGroup.color,
        hidden: !dataGroup.visible,
        data: dataGroup.dataList.map((data) => data.y),
        spanGaps: true,
      })),
    };
  }
}
