import { Mapper } from "../../../../../harmony/core";
import { PageDto } from "../../../../features/shared/data/page.parser";
import { AggregatedPublicationsPage } from "../../data/entities/aggregated-publications-page";
import { AggregatedPublicationsPageDto } from "../../data/entities/aggregated-publications-page.parser";

export class AggregatedPublicationsPageMapper<From, To>
  implements Mapper<PageDto<From>, AggregatedPublicationsPage<To>>
{
  constructor(private readonly mapper: Mapper<From, To>) {}

  public map(
    from: AggregatedPublicationsPageDto<From>,
  ): AggregatedPublicationsPage<To> {
    return new AggregatedPublicationsPage(
      from.pageNumber,
      from.size,
      from.totalSize,
      from.entries ? from.entries.map((entry) => this.mapper.map(entry)) : [],
      from.kpis,
    );
  }
}
