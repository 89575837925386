<button
  mat-icon-button
  (click)="onActionChangeViewType(ContentViewType.Grid)"
  [ngClass]="{ active: activeViewType() === ContentViewType.Grid }"
>
  <mat-icon>apps</mat-icon>
</button>
<button
  mat-icon-button
  (click)="onActionChangeViewType(ContentViewType.List)"
  [ngClass]="{ active: activeViewType() === ContentViewType.List }"
>
  <mat-icon>format_list_bulleted</mat-icon>
</button>
