import dayjs from "dayjs";
import {
  FilterOutput,
  FilterType,
} from "../../../shared/components/filtering/filter.interfaces";

export const mapFilterToHttpParametersMapper = (
  filters: FilterOutput[],
): Record<string, string[]> =>
  filters.reduce(
    (acc, filter) => {
      const key =
        filter.type === FilterType.TimePeriod ? filter.value.name : filter.type;

      // todo: create a specific interface with Date as a id type and a | object
      const value =
        filter.type === FilterType.TimePeriod
          ? dayjs(filter.value.id).startOf("day").toISOString().split(".")[0] +
            "Z"
          : filter.value.id;

      if (acc[key]) {
        acc[key].push(`${value}`);
      } else {
        acc[key] = [`${value}`];
      }
      return acc;
    },
    {} as Record<string, string[]>,
  );
