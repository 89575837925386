import { Cached } from "../../../../harmony/core";
import { PublicationService } from "../data/publication.service";
import { GetPublicationAggregatedListWithIntentsInteractor } from "./interactors/get-publication-aggregated-list-with-intents.interactor";
import { GetPublicationAggregatedListWithSummaryInteractor } from "./interactors/get-publication-aggregated-list-with-summary.interactor";
import { GetPublicationAggregatedTilesWithSummaryInteractor } from "./interactors/get-publication-aggregated-tiles-with-summary.interactor";

export abstract class PublicationProvider {
  abstract provideGetPublicationAggregatedListWithSummary(): GetPublicationAggregatedListWithSummaryInteractor;
  abstract provideGetPublicationAggregatedListWithIntents(): GetPublicationAggregatedListWithIntentsInteractor;
  abstract provideGetPublicationAggregatedTilesWithSummary(): GetPublicationAggregatedTilesWithSummaryInteractor;
}

export class PublicationDefaultProvider implements PublicationProvider {
  constructor(private readonly publicationService: PublicationService) {}

  @Cached()
  public provideGetPublicationAggregatedListWithSummary(): GetPublicationAggregatedListWithSummaryInteractor {
    return new GetPublicationAggregatedListWithSummaryInteractor(
      this.publicationService,
    );
  }

  @Cached()
  public provideGetPublicationAggregatedListWithIntents(): GetPublicationAggregatedListWithIntentsInteractor {
    return new GetPublicationAggregatedListWithIntentsInteractor(
      this.publicationService,
    );
  }

  @Cached()
  public provideGetPublicationAggregatedTilesWithSummary(): GetPublicationAggregatedTilesWithSummaryInteractor {
    return new GetPublicationAggregatedTilesWithSummaryInteractor(
      this.publicationService,
    );
  }
}
