import {
  ActiveElement,
  ChartType,
  Point,
  Tooltip,
  TooltipModel,
  TooltipPosition,
  TooltipPositionerFunction,
  TooltipYAlignment,
} from "chart.js";

declare module "chart.js" {
  interface TooltipPositionerMap {
    custom: TooltipPositionerFunction<ChartType>;
  }
}

export const registerCustomPositioner = () => {
  if (typeof Tooltip.positioners.custom === "function") {
    return;
  }

  Tooltip.positioners.custom = function (
    this: TooltipModel<ChartType>,
    items: readonly ActiveElement[],
    eventPosition: Point,
  ): false | TooltipPosition {
    const pos = Tooltip.positioners.nearest.bind(this)(items, eventPosition);
    const diff = this.chart.height - (this.chart.tooltip?.height ?? 0);
    let yAlign: TooltipYAlignment = "center";

    if (pos === false) {
      return false;
    }

    if (pos.y < diff) {
      yAlign = "top";
    } else if (pos.y > diff) {
      yAlign = "bottom";
    }

    return {
      x: pos.x,
      y: pos.y,
      xAlign: "center",
      yAlign: yAlign,
    };
  };
};
