import { NgClass } from "@angular/common";
import { Component, EventEmitter, Output, signal } from "@angular/core";
import { MatIconButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { ContentViewType } from "../../content-view-type.enum";

@Component({
  standalone: true,
  selector: "app-content-view-type-selector",
  templateUrl: "./content-view-type-selector.component.html",
  styleUrl: "./content-view-type-selector.component.scss",
  imports: [MatIcon, NgClass, MatIconButton],
})
export class ContentViewTypeSelectorComponent {
  @Output() readonly viewTypeChange = new EventEmitter<ContentViewType>();
  protected readonly ContentViewType = ContentViewType;
  protected readonly activeViewType = signal<ContentViewType>(
    ContentViewType.Grid,
  );

  protected onActionChangeViewType(viewType: ContentViewType): void {
    this.activeViewType.set(viewType);
    this.viewTypeChange.emit(viewType);
  }
}
