@if (publication().recommendedSchedules.dates.length) {
  <div class="schedule-list">
    @for (date of publication().recommendedSchedules.dates; track $index) {
      <div class="schedule">
        <span>
          {{ "publicationTemplate.schedule.scheduledFor" | translate }}
        </span>
        <span>{{ date | date: dateTimeFormat() }}</span>
      </div>
    }
  </div>
}
@if (publication().summary; as summary) {
  <div class="summary">
    <div>
      <div class="chip">
        <span class="bold">{{ summary.published }}</span>
        {{ "publicationTemplate.status.PUBLISHED" | translate }}
      </div>
    </div>
    <div>
      <div class="chip">
        <span class="bold">{{ summary.scheduled }}</span>
        {{ "publicationTemplate.status.SCHEDULED" | translate }}
      </div>
    </div>
    @if (summary.withIssues) {
      <div>
        <div class="chip warning">
          {{ summary.withIssues }}
          {{ "publicationTemplate.status.ISSUES" | translate }}
        </div>
      </div>
    }
    @if (summary.notAccepted) {
      <div>
        <div class="chip warning">
          {{ summary.notAccepted }}
          {{ "publicationTemplate.status.NOT_ACCEPTED" | translate }}
        </div>
      </div>
    }
  </div>
}
@if (
  !publication().summary &&
  publication().recommendedSchedules.dates.length === 0
) {
  <button class="btn btn--transparent btn--schedule">
    <mat-icon>calendar_today</mat-icon>
    <span>{{ "publicationTemplate.schedule.scheduleNow" | translate }}</span>
  </button>
}
