<div class="ph-item">
  <div class="post-container">
    <div class="post-header">
      <div class="section">
        <div class="header-icon ph-avatar"></div>
        <div class="ph-text ph-col-4"></div>
      </div>
    </div>
    <div class="post-body">
      <div class="ph-post-media"></div>
    </div>
    <div class="post-footer">
      <div class="summary">
        <div><div class="chip ph-chip"></div></div>
        <div><div class="chip ph-chip"></div></div>
        <div><div class="chip ph-chip"></div></div>
        <div><div class="chip ph-chip"></div></div>
      </div>
    </div>
  </div>
</div>
