export class PublicationAggregatedSummary {
  constructor(
    public readonly published: number,
    public readonly scheduled: number,
    public readonly notAccepted: number,
    public readonly withIssues: number,
  ) {}
}

export class PublicationAggregatedIntent {
  constructor(
    public readonly id: string,
    public readonly date: Date,
    public readonly publisherId: number,
    public readonly publisherName: string,
  ) {}
}

export class PublicationAggregatedIntentGroup {
  public readonly areThereMore: boolean;
  public readonly intents: PublicationAggregatedIntent[];

  constructor(list: PublicationAggregatedIntent[]) {
    this.areThereMore = false; // todo: list.length > 3;
    this.intents = list; // todo: list.slice(0, 3);
  }
}

export class PublicationAggregatedSchedulesGroup {
  public readonly areThereMore: boolean;
  public readonly dates: Date[];

  constructor(list: Date[]) {
    this.areThereMore = false; // todo: list.length > 3;
    this.dates = list; // todo: list.slice(0, 3);
  }
}

export class PublicationAggregatedIntentsGroups {
  constructor(
    public readonly scheduled?: PublicationAggregatedIntentGroup,
    public readonly published?: PublicationAggregatedIntentGroup,
    public readonly notAccepted?: PublicationAggregatedIntentGroup,
    public readonly withIssues?: PublicationAggregatedIntentGroup,
  ) {}
}
