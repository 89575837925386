import { Page } from "../../../../features/shared/domain/models/page";
import { PublicationAggregatedKPIs } from "./publication.entity";

export class AggregatedPublicationsPage<T> extends Page<T> {
  constructor(
    pageNumber: number,
    size: number,
    totalSize: number,
    entries: T[],
    public readonly kpis: PublicationAggregatedKPIs,
  ) {
    super(pageNumber, size, totalSize, entries);
  }
}
