import { Component, EventEmitter, input, OnInit, Output } from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { RouterLink } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { BrandUrl } from "../../../../brand.url";

@Component({
  standalone: true,
  selector: "app-brand-publication-template-list-item-actions",
  templateUrl: "./brand-publication-template-list-item-actions.component.html",
  styleUrl: "./brand-publication-template-list-item-actions.component.scss",
  imports: [
    RouterLink,
    MatMenuTrigger,
    MatIcon,
    MatMenu,
    MatMenuItem,
    TranslateModule,
  ],
})
export class BrandPublicationTemplateListItemActionsComponent
  implements OnInit
{
  @Output() public readonly remove = new EventEmitter<void>();
  public readonly publicationId = input.required<string>();
  public readonly campaignId = input.required<number>();
  public readonly creatorId = input.required<number>();

  protected editingUrl?: string;
  protected schedulingUrl?: string;

  public ngOnInit(): void {
    const publicationId = this.publicationId();
    const publicationTemplateUrls = BrandUrl.Campaign.PublicationTemplate(
      this.creatorId(),
      this.campaignId(),
    );
    this.editingUrl = publicationTemplateUrls.Edit(publicationId);
    this.schedulingUrl = publicationTemplateUrls.Schedule(publicationId);
  }
}
