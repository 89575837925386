import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Chart } from "chart.js";
import type { ChartOptions, Plugin } from "chart.js";
import { Subscription } from "rxjs";
import { ChartJSBarChart } from "../../charts/adapters/bar-chart";
import { ChartJSDoughnutChart } from "../../charts/adapters/doughnut-chart";
import { ChartJSLineChart } from "../../charts/adapters/line-chart";

import {
  BarChartSettings,
  DoughnutChartSettings,
  LineChartSettings,
  ChartSettings,
} from "../../charts/models/chart-settings.model";
import { cloneWith } from "../../services/clonable";
import { LanguageService } from "../../services/language.service";

@Component({
  selector: "app-chart",
  templateUrl: "./chart.component.html",
  styleUrls: ["./chart.component.scss"],
  standalone: true,
})
export class ChartComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input({ required: true }) public settings!: ChartSettings;
  @ViewChild("canvas") public canvas?: ElementRef<HTMLCanvasElement>;
  private chart?: Chart;
  private subscriptions = new Subscription();

  constructor(
    private readonly languageService: LanguageService,
    private readonly translateService: TranslateService,
  ) {
    this.subscriptions.add(
      this.languageService.changed$.subscribe(() => {
        this.createChartInstance();
      }),
    );
  }

  public ngAfterViewInit(): void {
    if (this.settings && !this.chart) {
      this.createChartInstance();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.settings && this.chart) {
      this.createChartInstance();
    }
  }

  public ngOnDestroy(): void {
    if (this.chart) {
      this.chart.destroy();
    }
    this.subscriptions.unsubscribe();
  }

  private createChartInstance(): void {
    if (!this.canvas) {
      return;
    }

    const settings = this.translate(this.settings);
    const { options, plugins } = settings.options(this.translateService);

    if (settings instanceof DoughnutChartSettings) {
      this.chart = new ChartJSDoughnutChart().createOrUpdate(
        this.canvas.nativeElement,
        settings.dataList,
        settings.dataGroupList,
        options as ChartOptions<"doughnut">,
        plugins as Plugin[],
      );
    } else if (settings instanceof BarChartSettings) {
      this.chart = new ChartJSBarChart().build(
        this.canvas.nativeElement,
        settings.dataList,
        options as ChartOptions<"bar">,
      );
    } else if (settings instanceof LineChartSettings) {
      this.chart = new ChartJSLineChart().createOrUpdate(
        this.canvas.nativeElement,
        settings.dataGroupList,
        options as ChartOptions<"line">,
      );
    } else {
      throw new Error("Chart settings not supported");
    }
  }

  private translate(settings: ChartSettings): ChartSettings {
    settings = cloneWith(settings);

    if (settings.dataGroupList) {
      settings.dataGroupList = settings.dataGroupList.map((dataGroup) =>
        cloneWith(dataGroup),
      );
      settings.dataGroupList.map((dataGroup) => {
        dataGroup.name = this.translateService.instant(dataGroup.name);
      });
    }

    if (settings.dataList) {
      settings.dataList = settings.dataList.map((data) => cloneWith(data));
      settings.dataList.map((data) => {
        data.x = this.translateService.instant(data.x).split(" ");
      });
    }

    return settings;
  }
}
