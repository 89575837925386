import { NgForOf } from "@angular/common";
import {
  Component,
  EventEmitter,
  input,
  OnInit,
  Output,
  signal,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatOption } from "@angular/material/autocomplete";
import { MatFormField } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { MatInput } from "@angular/material/input";
import { MatSelect } from "@angular/material/select";
import { TranslateModule } from "@ngx-translate/core";
import { SortDir } from "../../../../features/shared/data/sort-dir.enum";
import { SharedPipesModule } from "../../../pipes/shared-pipes.module";
import { AutocompleteSelectorComponent } from "../autocomplete-selector/autocomplete-selector.component";
import { SelectedChipComponent } from "../selected-chip/selected-chip.component";

export interface SortingOption {
  field: string;
  name: string;
  dir: SortDir;
}

@Component({
  standalone: true,
  selector: "app-sorting-selector",
  templateUrl: "./sorting-selector.component.html",
  styleUrl: "./sorting-selector.component.scss",
  imports: [
    TranslateModule,
    MatFormField,
    MatOption,
    MatSelect,
    NgForOf,
    FormsModule,
    MatIcon,
    MatInput,
    AutocompleteSelectorComponent,
    SelectedChipComponent,
    SharedPipesModule,
  ],
})
export class SortingSelectorComponent implements OnInit {
  @Output() public optionSelected = new EventEmitter<SortingOption>();
  protected readonly options = input.required<SortingOption[]>();
  protected readonly areFiltersOpen = signal(false);
  protected readonly selectedOption = signal<SortingOption | undefined>(
    undefined,
  );

  public ngOnInit(): void {
    this.selectedOption.set(this.options()[0]);
  }

  protected onActionOptionSelected(option: SortingOption): void {
    this.selectedOption.set(option);
    this.optionSelected.emit(option);
    this.areFiltersOpen.set(false);
  }

  protected onActionToggleFilters(): void {
    this.areFiltersOpen.update((areFiltersOpen) => !areFiltersOpen);
  }

  public reset(): void {
    this.selectedOption.set(this.options()[0]);
  }
}
