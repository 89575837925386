import { PublicationTemplateType } from "../../../../brand/brand-campaign/publication-template/data/entities/publication-templates.entity";
import { Platform } from "../../../enums/campaignPost.enums";
import {
  PublicationAggregatedIntentsGroups,
  PublicationAggregatedSchedulesGroup,
  PublicationAggregatedSummary,
} from "./publication";

export class PublicationAggregatedListViewItem {
  constructor(
    public readonly publicationTemplateId: string,
    public readonly publicationTemplateType: PublicationTemplateType,
    public readonly thumbnailUrl: string,
    public readonly campaignId: number,
    public readonly campaignName: string,
    public readonly recommendedSchedules: PublicationAggregatedSchedulesGroup,
    public readonly creatorId: number,
    public readonly creatorName: string,
    public readonly lastPublishedAt: Date,
    public readonly platform: Platform,
  ) {}
}

export class PublicationAggregatedListViewItemWithSummary extends PublicationAggregatedListViewItem {
  constructor(
    publicationTemplateId: string,
    publicationTemplateType: PublicationTemplateType,
    thumbnailUrl: string,
    campaignId: number,
    campaignName: string,
    recommendedSchedules: PublicationAggregatedSchedulesGroup,
    creatorId: number,
    creatorName: string,
    lastPublishedAt: Date,
    platform: Platform,
    public readonly summary: PublicationAggregatedSummary,
  ) {
    super(
      publicationTemplateId,
      publicationTemplateType,
      thumbnailUrl,
      campaignId,
      campaignName,
      recommendedSchedules,
      creatorId,
      creatorName,
      lastPublishedAt,
      platform,
    );
  }
}

export class PublicationAggregatedListViewItemWithIntents extends PublicationAggregatedListViewItem {
  constructor(
    publicationTemplateId: string,
    publicationTemplateType: PublicationTemplateType,
    thumbnailUrl: string,
    campaignId: number,
    campaignName: string,
    recommendedSchedules: PublicationAggregatedSchedulesGroup,
    creatorId: number,
    creatorName: string,
    lastPublishedAt: Date,
    platform: Platform,
    public readonly intents?: PublicationAggregatedIntentsGroups,
  ) {
    super(
      publicationTemplateId,
      publicationTemplateType,
      thumbnailUrl,
      campaignId,
      campaignName,
      recommendedSchedules,
      creatorId,
      creatorName,
      lastPublishedAt,
      platform,
    );
  }
}
