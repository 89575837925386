import {
  Chart,
  ChartDataset,
  ChartOptions,
  registerables,
  UpdateMode,
  Plugin,
} from "chart.js";

import { ChartDataGroup } from "../models/chart-data-group.model";
import { ChartData } from "../models/chart-data.model";

export class ChartJSDoughnutChart {
  private readonly UPDATE_MODE_NONE: UpdateMode = "none";
  constructor() {
    Chart.register(...registerables);
  }

  public createOrUpdate(
    canvas: HTMLCanvasElement,
    dataList: ChartData[],
    dataGroupList: ChartDataGroup[],
    options?: ChartOptions<"doughnut">,
    plugins?: Plugin[],
  ): Chart {
    /* eslint-disable-next-line no-prototype-builtins */
    if (canvas.hasOwnProperty("$chartjs")) {
      return this.update(canvas, dataList, dataGroupList);
    }

    return this.build(
      canvas,
      dataList,
      dataGroupList,
      options ? (options as ChartOptions) : undefined,
      plugins,
    );
  }

  public build(
    canvas: HTMLCanvasElement,
    dataList: ChartData[],
    dataGroupList: ChartDataGroup[],
    options?: ChartOptions,
    plugins?: Plugin[],
  ): Chart {
    return new Chart(canvas, {
      type: "doughnut",
      data: {
        datasets: this.getDatasets(dataList, dataGroupList),
      },
      options: {
        ...options,
        animation: false,
      },
      plugins: plugins,
    });
  }

  public update(
    canvas: HTMLCanvasElement,
    dataList: ChartData[],
    dataGroupList: ChartDataGroup[],
  ): Chart {
    const chart = Chart.getChart(canvas) as Chart;

    if (chart) {
      chart.data.datasets = this.getDatasets(dataList, dataGroupList);
      chart.update(this.UPDATE_MODE_NONE);
    }

    return chart;
  }

  private getDatasets(
    dataList: ChartData[],
    dataGroupList: ChartDataGroup[],
  ): ChartDataset[] {
    const dataSets = dataList
      ? [this.getDataSet(dataList)]
      : dataGroupList.map((dataGroup) => this.getDataSet(dataGroup.dataList));

    if (this.isDataSetsEmpty(dataSets)) {
      return [
        {
          data: [0, 1],
          backgroundColor: "#dfdfdf",
          borderWidth: 0,
        },
      ];
    }

    return dataSets;
  }

  private isDataSetsEmpty(dataSets: ChartDataset[]): boolean {
    const foundData = dataSets.find((dataSet) =>
      dataSet.data.find((data) => (data ? +data > 0 : false)),
    );

    return !foundData;
  }

  private getDataSet(dataList: ChartData[]): ChartDataset {
    return {
      data: dataList.map((data) => data.y),
      backgroundColor: dataList.map((data) => data.color),
      borderWidth: 0,
    };
  }
}
