import { mapFilterToHttpParametersMapper } from "../../../../features/selector-options/mappers/filter-to-http-params.mapper";
import { FilterOutput } from "../../../components/filtering/filter.interfaces";
import { SortingOption } from "../../../components/filtering/sorting-selector/sorting-selector.component";
import { AggregatedPublicationsPage } from "../../data/entities/aggregated-publications-page";
import { type PublicationService } from "../../data/publication.service";
import { PublicationAggregatedListViewItemWithIntents } from "../models/publication-list-view";

export class GetPublicationAggregatedListWithIntentsInteractor {
  constructor(private readonly publicationService: PublicationService) {}

  public async execute(
    size: number,
    page?: number,
    filters?: FilterOutput[],
    sorting?: SortingOption,
  ): Promise<
    AggregatedPublicationsPage<PublicationAggregatedListViewItemWithIntents>
  > {
    return this.publicationService.getPublicationAggregatedListWithIntents(
      filters ? mapFilterToHttpParametersMapper(filters) : {},
      sorting,
      page,
      size,
    );
  }
}
