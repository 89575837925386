import { NgClass } from "@angular/common";
import {
  Component,
  EventEmitter,
  input,
  OnInit,
  Output,
  signal,
} from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { GetSelectorOptionsInteractor } from "../../../../features/selector-options/selector-options.providers";
import { FilterOutput, FilterType, SelectorOption } from "../filter.interfaces";

@Component({
  standalone: true,
  selector: "app-filters-row",
  templateUrl: "./filters-row.component.html",
  styleUrl: "./filters-row.component.scss",
  imports: [NgClass, TranslateModule],
})
export class FiltersRowComponent implements OnInit {
  private readonly AllOption = {
    id: undefined,
    name: "all",
  };
  @Output() public optionSelected = new EventEmitter<
    FilterOutput | undefined
  >();
  public readonly interactor = input.required<GetSelectorOptionsInteractor>();
  public readonly type = input.required<FilterType>();
  protected options = signal<SelectorOption[]>([this.AllOption]);
  protected selectedOption = signal<SelectorOption>(this.options()[0]);
  private allOptions: SelectorOption[] = [];

  public async ngOnInit(): Promise<void> {
    const pagedOptions = await this.interactor().execute();
    this.allOptions = pagedOptions.entries;
    this.options.update((option) => [...option, ...this.allOptions]);
  }

  protected onActionOptionSelected(option: SelectorOption): void {
    this.selectedOption.update(() => option);
    this.optionSelected.emit(
      option.id
        ? {
            type: this.type(),
            value: option,
          }
        : undefined,
    );
  }

  public reset(): void {
    this.options.set([this.AllOption, ...this.allOptions]);
    this.onActionOptionSelected(this.options()[0]);
  }
}
