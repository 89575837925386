import { PublicationTemplate } from "../../../../brand/brand-campaign/publication-template/domain/models/publication-template";
import { Platform } from "../../../enums/campaignPost.enums";
import {
  PublicationAggregatedSchedulesGroup,
  PublicationAggregatedSummary,
} from "./publication";

export class PublicationAggregatedTilesViewItem {
  constructor(
    public readonly publicationTemplate: PublicationTemplate,
    public readonly thumbnailUrl: string,
    public readonly campaignId: number,
    public readonly campaignName: string,
    public readonly recommendedSchedules: PublicationAggregatedSchedulesGroup,
    public readonly creatorId: number,
    public readonly creatorName: string,
    public readonly lastPublishedAt: Date,
    public readonly platform: Platform,
  ) {}
}

export class PublicationAggregatedTilesViewItemWithSummary extends PublicationAggregatedTilesViewItem {
  constructor(
    publicationTemplate: PublicationTemplate,
    thumbnailUrl: string,
    campaignId: number,
    campaignName: string,
    recommendedSchedules: PublicationAggregatedSchedulesGroup,
    creatorId: number,
    creatorName: string,
    lastPublishedAt: Date,
    platform: Platform,
    public readonly summary: PublicationAggregatedSummary,
  ) {
    super(
      publicationTemplate,
      thumbnailUrl,
      campaignId,
      campaignName,
      recommendedSchedules,
      creatorId,
      creatorName,
      lastPublishedAt,
      platform,
    );
  }
}
