<div class="post-container">
  <div class="post-header">
    <div class="section">
      <img
        [src]="
          '/assets/images/icons/ic_' + publication().platform + 'Content.svg'
        "
        class="header-icon"
        alt="instagram-icon"
      />
      <span class="post-type">
        {{
          "publicationTemplatePostContainer." +
            (publication().publicationTemplate.publicationTemplateType
              | caseTransform: "SnakeToCamel") | translate
        }}
      </span>
    </div>
    @if (areActionVisible()) {
      <app-brand-publication-template-list-item-actions
        [publicationId]="publication().publicationTemplate.id"
        [campaignId]="publication().campaignId"
        [creatorId]="publication().creatorId"
        (remove)="remove.emit()"
      />
    }
  </div>
  <div class="post-body">
    <ng-content />
  </div>
  <div class="post-footer">
    <app-publication-tile-footer [publication]="publication()" />
  </div>
</div>
