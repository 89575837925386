<a class="edit-button btn btn--content-only" [routerLink]="editingUrl">
  <mat-icon class="header-icon-btn">edit</mat-icon>
</a>
<a class="edit-button btn btn--content-only" [routerLink]="schedulingUrl">
  <mat-icon class="header-icon-btn">calendar_today</mat-icon>
</a>
<button
  [matMenuTriggerFor]="menuRight"
  class="btn btn--content-only menu-trigger"
>
  <mat-icon class="header-icon-btn">more_horiz</mat-icon>
</button>
<mat-menu #menuRight="matMenu">
  <div mat-menu-item (click)="remove.emit()">
    <div class="container-menu-item">
      <img src="/assets/images/publication-template/preview/delete.svg" />
      <span>
        {{ "publicationTemplatePostContainer.deletePost" | translate }}
      </span>
    </div>
  </div>
</mat-menu>
