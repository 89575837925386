<div class="container" (clickOutside)="areFiltersOpen.set(false)">
  <span>{{ "sorting.sortBy" | translate }}</span>
  @if (selectedOption()?.name; as name) {
    <button class="selector-button" (click)="onActionToggleFilters()">
      {{ "sorting." + name | translate }}
      <mat-icon>arrow_drop_down</mat-icon>
      @if (areFiltersOpen()) {
        <div class="option-list">
          @for (option of options(); track $index) {
            <button class="filter" (click)="onActionOptionSelected(option)">
              {{ "sorting." + option.name | translate }}
            </button>
          }
        </div>
      }
    </button>
  }
</div>
