import { Chart, ChartOptions, registerables } from "chart.js";
import { ChartData } from "../models/chart-data.model";

export class ChartJSBarChart {
  constructor() {
    Chart.register(...registerables);
  }

  public build(
    canvas: HTMLCanvasElement,
    dataList?: ChartData[],
    options?: ChartOptions,
  ): Chart {
    dataList = dataList ?? [];

    return new Chart(canvas, {
      type: "bar",
      data: {
        labels: dataList.map((data) => data.x),
        datasets: [
          {
            label: "dataset",
            data: dataList.map((data) => data.y),
            backgroundColor: dataList.map((data) => data.color),
          },
        ],
      },
      options: options,
    });
  }
}
