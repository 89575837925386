<div class="ph-item">
  <div class="thumbnail ph-avatar"></div>
  <div class="details">
    <div class="platform">
      <div class="ph-text square"></div>
      <div class="ph-text ph-col-2"></div>
    </div>
    <div class="ph-text ph-col-12"></div>
    <div class="ph-text ph-col-12"></div>
    <div class="summary">
      <div class="ph-text ph-col-10"></div>
      <div class="ph-text ph-col-10"></div>
      <div class="ph-text ph-col-10"></div>
    </div>
  </div>
</div>
