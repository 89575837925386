import { Component, Output, EventEmitter, input } from "@angular/core";
import { MatIconButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";

@Component({
  standalone: true,
  selector: "app-paginator",
  templateUrl: "./paginator.component.html",
  styleUrl: "./paginator.component.scss",
  imports: [MatIcon, MatIconButton],
})
export class PaginatorComponent {
  public readonly totalPages = input.required<number>();
  public readonly currentPage = input.required<number>();
  @Output() public readonly pageChange = new EventEmitter<number>();

  protected prevPage(): void {
    this.goToPage(this.currentPage() - 1);
  }

  protected nextPage(): void {
    this.goToPage(this.currentPage() + 1);
  }

  protected goToPage(page: number | undefined): void {
    if (
      page &&
      !isNaN(page) &&
      page >= 1 &&
      page <= this.totalPages() &&
      page !== this.currentPage()
    ) {
      this.pageChange.emit(page);
    }
  }
}
