import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { HttpParameters } from "../../../../harmony/core";
import { SortingOption } from "../../components/filtering/sorting-selector/sorting-selector.component";
import { StrictApiRequestService } from "../../http/strict-api-request.service";
import { AuthNetworkService } from "../../services/api/auth.network.service";
import { AggregatedPublicationsPageMapper } from "../domain/mappers/aggregated-publications-page.mapper";

import {
  PublicationAggregatedListViewItemWithIntentsMapper,
  PublicationAggregatedListViewItemWithSummaryMapper,
  PublicationAggregatedTilesViewItemWithSummaryMapper,
} from "../domain/mappers/publication.mapper";
import {
  PublicationAggregatedListViewItemWithIntents,
  PublicationAggregatedListViewItemWithSummary,
} from "../domain/models/publication-list-view";
import { PublicationAggregatedTilesViewItemWithSummary } from "../domain/models/publication-tiles-view";
import { AggregatedPublicationsPage } from "./entities/aggregated-publications-page";

@Injectable()
export class PublicationService {
  private readonly apiRequestService: StrictApiRequestService;
  private readonly monolithApiRequestService: StrictApiRequestService;

  constructor(
    @Inject("API_GATEWAY_URL") API_GATEWAY_URL: string,
    @Inject("MONOLITH_API_URL") API_URL: string,
    httpClient: HttpClient,
    authNetworkService: AuthNetworkService,
  ) {
    this.apiRequestService = new StrictApiRequestService(
      `${API_GATEWAY_URL}/publication`,
      httpClient,
      authNetworkService,
    );

    this.monolithApiRequestService = new StrictApiRequestService(
      API_URL,
      httpClient,
      authNetworkService,
    );
  }

  public getPublicationAggregatedListWithSummary(
    filters: HttpParameters,
    sorting?: SortingOption,
    page = 1,
    size = 10,
  ): Promise<
    AggregatedPublicationsPage<PublicationAggregatedListViewItemWithSummary>
  > {
    return this.apiRequestService
      .path("/aggregated/list-view")
      .setQueryParams({
        ...filters,
        sort: sorting?.field,
        dir: sorting?.dir,
        page: page,
        size: size,
      })
      .setMapper(
        new AggregatedPublicationsPageMapper(
          new PublicationAggregatedListViewItemWithSummaryMapper(),
        ),
      )
      .get();
  }

  public getPublicationAggregatedTilesWithSummary(
    filters: HttpParameters,
    sorting?: SortingOption,
    page = 1,
    size = 10,
  ): Promise<
    AggregatedPublicationsPage<PublicationAggregatedTilesViewItemWithSummary>
  > {
    return this.apiRequestService
      .path("/aggregated/tile-view")
      .setQueryParams({
        ...filters,
        sort: sorting?.field,
        dir: sorting?.dir,
        page: page,
        size: size,
      })
      .setMapper(
        new AggregatedPublicationsPageMapper(
          new PublicationAggregatedTilesViewItemWithSummaryMapper(),
        ),
      )
      .get();
  }

  public getPublicationAggregatedListWithIntents(
    filters: HttpParameters,
    sorting?: SortingOption,
    page = 1,
    size = 10,
  ): Promise<
    AggregatedPublicationsPage<PublicationAggregatedListViewItemWithIntents>
  > {
    return this.apiRequestService
      .path("/aggregated/list-view")
      .setQueryParams({
        ...filters,
        sort: sorting?.field,
        dir: sorting?.dir,
        page: page,
        size: size,
      })
      .setMapper(
        new AggregatedPublicationsPageMapper(
          new PublicationAggregatedListViewItemWithIntentsMapper(),
        ),
      )
      .get();
  }

  public acceptCalendar(
    partnerId: number,
    campaignId: number,
    campaignDetailsId: number,
    calendarId: number,
    data: any,
  ): Promise<void> {
    return this.monolithApiRequestService
      .path(
        "partner/:partnerId/campaign/:campaignId/detail/:detailsId/approve_calendar/:calendarId",
      )
      .setUrlParams({
        partnerId: partnerId,
        campaignId: campaignId,
        detailsId: campaignDetailsId,
        calendarId: calendarId,
      })
      .setBody(data)
      .post();
  }
}
