@for (option of options(); track option.id) {
  <button
    class="btn filter"
    (click)="onActionOptionSelected(option)"
    [ngClass]="{
      'btn--primary': option.id === selectedOption().id,
      'btn--gray': option.id !== selectedOption().id
    }"
  >
    {{ "filter." + option.name | translate }}
  </button>
}
