import { PublicationTemplateEntityToPublicationTemplateMapper } from "../../../../brand/brand-campaign/publication-template/domain/mappers/publication-template.mapper";
import { PublicationTemplatePlatform } from "../../../../brand/brand-campaign/publication-template/domain/models/publication-template";
import { PublicationAggregatedTilesViewItemWithSummaryEntity } from "../../data/entities/publication-tiles-view.entity";
import {
  PublicationAggregatedIntentEntity,
  PublicationAggregatedIntentsGroupsEntity,
  PublicationAggregatedListViewItemWithIntentsEntity,
  PublicationAggregatedListViewItemWithSummaryEntity,
} from "../../data/entities/publication.entity";
import {
  PublicationAggregatedIntent,
  PublicationAggregatedIntentGroup,
  PublicationAggregatedIntentsGroups,
  PublicationAggregatedSchedulesGroup,
} from "../models/publication";
import {
  PublicationAggregatedListViewItemWithIntents,
  PublicationAggregatedListViewItemWithSummary,
} from "../models/publication-list-view";
import { PublicationAggregatedTilesViewItemWithSummary } from "../models/publication-tiles-view";

export class PublicationAggregatedListViewItemWithSummaryMapper {
  public map(
    from: PublicationAggregatedListViewItemWithSummaryEntity,
  ): PublicationAggregatedListViewItemWithSummary {
    return new PublicationAggregatedListViewItemWithSummary(
      from.publicationTemplateId,
      from.publicationTemplateType,
      from.thumbnailUrl,
      from.campaignId,
      from.campaignName,
      new PublicationAggregatedSchedulesGroup(
        from.recommendedSchedules.map((dateString) => new Date(dateString)),
      ),
      from.creatorId,
      from.creatorName,
      new Date(from.lastPublishedAt),
      PublicationTemplatePlatform[from.publicationTemplateType],
      {
        scheduled: from.summary.scheduled ?? undefined,
        published: from.summary.published ?? undefined,
        notAccepted: from.summary.notAccepted ?? undefined,
        withIssues: from.summary.withIssues ?? undefined,
      },
    );
  }
}

export class PublicationAggregatedListViewItemWithIntentsMapper {
  public map(
    from: PublicationAggregatedListViewItemWithIntentsEntity,
  ): PublicationAggregatedListViewItemWithIntents {
    const groupMapper = new PublicationAggregatedIntentsGroupsMapper();
    return new PublicationAggregatedListViewItemWithIntents(
      from.publicationTemplateId,
      from.publicationTemplateType,
      from.thumbnailUrl,
      from.campaignId,
      from.campaignName,
      new PublicationAggregatedSchedulesGroup(
        from.recommendedSchedules.map((dateString) => new Date(dateString)),
      ),
      from.creatorId,
      from.creatorName,
      new Date(from.lastPublishedAt),
      PublicationTemplatePlatform[from.publicationTemplateType],
      groupMapper.map(from.intents),
      /* new PublicationAggregatedIntentsGroups(
        new PublicationAggregatedIntentGroup([
          new PublicationAggregatedIntent("1", new Date(), 1, "Publisher 1"),
          new PublicationAggregatedIntent("1", new Date(), 1, "Publisher 1"),
          new PublicationAggregatedIntent("1", new Date(), 1, "Publisher 1"),
          new PublicationAggregatedIntent("1", new Date(), 1, "Publisher 1"),
        ]),
        new PublicationAggregatedIntentGroup([
          new PublicationAggregatedIntent("1", new Date(), 1, "Publisher 1"),
          new PublicationAggregatedIntent("1", new Date(), 1, "Publisher 1"),
          new PublicationAggregatedIntent("1", new Date(), 1, "Publisher 1"),
          new PublicationAggregatedIntent("1", new Date(), 1, "Publisher 1"),
        ]),
        new PublicationAggregatedIntentGroup([
          new PublicationAggregatedIntent("1", new Date(), 1, "Publisher 1"),
          new PublicationAggregatedIntent("1", new Date(), 1, "Publisher 1"),
          new PublicationAggregatedIntent("1", new Date(), 1, "Publisher 1"),
          new PublicationAggregatedIntent("1", new Date(), 1, "Publisher 1"),
        ]),
        new PublicationAggregatedIntentGroup([
          new PublicationAggregatedIntent("1", new Date(), 1, "Publisher 1"),
          new PublicationAggregatedIntent("1", new Date(), 1, "Publisher 1"),
          new PublicationAggregatedIntent("1", new Date(), 1, "Publisher 1"),
          new PublicationAggregatedIntent("1", new Date(), 1, "Publisher 1"),
        ]),
      ),*/
    );
  }
}

export class PublicationAggregatedIntentMapper {
  public map(
    from: PublicationAggregatedIntentEntity,
  ): PublicationAggregatedIntent {
    return new PublicationAggregatedIntent(
      from.publicationIntentId,
      new Date(from.date),
      from.publisherId,
      from.publisherName,
    );
  }
}

export class PublicationAggregatedIntentsGroupsMapper {
  public map(
    from: PublicationAggregatedIntentsGroupsEntity,
  ): PublicationAggregatedIntentsGroups {
    const intentMapper = new PublicationAggregatedIntentMapper();
    return new PublicationAggregatedIntentsGroups(
      new PublicationAggregatedIntentGroup(
        from.scheduled.map((intent) => intentMapper.map(intent)),
      ),
      new PublicationAggregatedIntentGroup(
        from.published.map((intent) => intentMapper.map(intent)),
      ),
      new PublicationAggregatedIntentGroup(
        from.notAccepted.map((intent) => intentMapper.map(intent)),
      ),
      new PublicationAggregatedIntentGroup(
        from.withIssues.map((intent) => intentMapper.map(intent)),
      ),
    );
  }
}

export class PublicationAggregatedTilesViewItemWithSummaryMapper {
  public map(
    from: PublicationAggregatedTilesViewItemWithSummaryEntity,
  ): PublicationAggregatedTilesViewItemWithSummary {
    const publicationTemplateMapper =
      new PublicationTemplateEntityToPublicationTemplateMapper();
    return new PublicationAggregatedTilesViewItemWithSummary(
      publicationTemplateMapper.map(from.publicationTemplate),
      from.thumbnailUrl,
      from.campaignId,
      from.campaignName,
      new PublicationAggregatedSchedulesGroup(
        from.recommendedSchedules.map((dateString) => new Date(dateString)),
      ),
      from.creatorId,
      from.creatorName,
      new Date(from.lastPublishedAt),
      PublicationTemplatePlatform[
        from.publicationTemplate.publicationTemplateType
      ],
      {
        scheduled: from.summary.scheduled ?? undefined,
        published: from.summary.published ?? undefined,
        notAccepted: from.summary.notAccepted ?? undefined,
        withIssues: from.summary.withIssues ?? undefined,
      },
    );
  }
}
